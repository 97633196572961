$.fn.autoComplete = function(params) {
    var element = $(this);
    var minLength = 2;
    if (params != undefined && params.minLength != undefined) {
        minLength = params.minLength;
    }

    element.attr('autocomplete', 'off');

    var getName = function(params, ui) {
      if (params != undefined && params.renderItem != undefined
          && !params.selectOnlyName)
          return params.renderItem(ui.item);
      else
          return ui.item.name;
    }

    element.autocomplete({
        dataType: 'json',
        minLength: minLength,

        source: function(request, response) {
            var depend = this.element[0].id.replace('address', 'location_id');
            var depend_id = $('#' + depend).val();
            var dataObject = {
                term: request.term,
                depend_id: depend_id
            };

            if (params!= undefined && params.additionalRequestParams != undefined) {
                Object.assign(dataObject, params.additionalRequestParams())
            }

            var dataPath = element.data('autocomplete-path');

            $.ajax({
                    url: dataPath,
                    dataType: "json",
                    data: dataObject,
                    success: function(data) {
                        response(data);
                    }
                }
            );
        },

        focus: function(event, ui) {
            element.val(getName(params, ui));
            return false;
        },

        select: function(event, ui) {
            element.val(getName(params, ui));

            if (params != undefined && params.itemId != undefined) {
                var id = params.itemId(ui.item);
            } else {
                var id = ui.item.id;
            }

            var hidden = $('#' + element.data('autocomplete-hidden'));
            hidden.val(id).trigger('change');
            if (params != undefined && params.afterSelect != undefined) {
              params.afterSelect(ui.item);
            }
            return false;
        },

        change: function(event, ui) {
            if (!ui.item) {
                var hidden = $('#' + element.data('autocomplete-hidden'));
                hidden.val(null).trigger('change');
            }
        }
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
        if (params != undefined && params.renderItem != undefined) {
            var name = params.renderItem(item);
        } else {
            var name = item.name;
        }

        return $('<li>')
            .data('item.autocomplete', item)
            .append("<a>" + name + "</a>")
            .appendTo(ul);
    };

    this.keypress(function(e) {
        if (e.which == 13) {
            var hidden = $('#' + element.data('autocomplete-hidden'));

            if ($(this).val() == '' && hidden.val() != '') {
                hidden.val(null);
            }
        }
    });
};

import RailsUjs from '@rails/ujs';
RailsUjs.start();

import 'jquery-ui/ui/disable-selection';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/widgets/tooltip';

import 'bootstrap';
import 'bootstrap-multiselect';
import './global/autocomplete';
import '../lib/phone_mask';

import '@fortawesome/fontawesome-free/js/all';
